import { Container, Box } from '@mui/material';

const MaintenanceWindow = () => {
    return (
        <>
            <Container sx={{height: '100%'}}>
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', minHeight: {xs: '90vh', md: '70vh'}}}>
                    <Box>
                        <h1>We&rsquo;ll be back soon!</h1>
                    </Box>
                    <Box>
                        <h3>
                            Sorry for the inconvenience. We’re performing some maintenance at the moment. 
                            If you need to, you can email us at <i><u>shortlshortener@gmail.com</u></i>, 
                            otherwise we’ll be back up shortly!
                        </h3>
                    </Box>
                    <Box>
                        <h3>— The Shortl Team</h3>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default MaintenanceWindow;